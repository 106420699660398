import React, { useRef, useEffect } from "react"
import { navigate } from "gatsby"
// import GravityFormForm from "gatsby-plugin-gravity-forms"
import ChriateForm from "../../components/ChriateForm/ChriateForm"

import "./Popup.scss"

const Popup = ({ isActive, handleClick }) => {
  const popupRef = useRef(null)
  // const handleSuccessCallback = ({ data }) => {
  //   // navigate('/thank-you');
  // }
  const formId = 1

  useEffect(() => {
    if (window?.scroll?.disable && isActive) {
      window.scroll.disable({ inputOnly: true })
    } else if (window?.scroll?.enable) {
      window.scroll.enable({ restore: true, horizontalScroll: true })
    }
  }, [isActive])

  const handleNavClick = e => {
    const { target } = e

    if (target.classList.contains("popup")) {
      handleClick()
    }
  }

  return (
    <>
      <nav
        className={`popup${isActive ? " active" : ""}`}
        onClick={e => handleNavClick(e)}
      >
        <div ref={popupRef} className="popup__wrapper">
          <div className="popup__close" onClick={handleClick}>
            <span></span>
            <span></span>
          </div>
          {formId ? <ChriateForm formId={formId} /> : ""}
        </div>
      </nav>
      <div className={`nav-bg ${isActive ? " active" : ""}`}></div>
    </>
  )
}

export default Popup
